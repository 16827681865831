import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import PropTypes from "prop-types";
import "antd/dist/antd.css";
import Loader from "../../components/Loader/Loader";
import IntegrationGoupImg from "../../assets/images/all-integ.png";
import { Link } from "react-router-dom";
import GoogleCalendar from "../../assets/icons/g-calendar.png";
import Android from "../../assets/icons/android.svg";
import Logo from "../../assets/images/logo.png";
import LogoIcon from "../../assets/icons/meetocto_icon.svg";
import Slack from "../../assets/icons/slack.svg";
import WordPress from "../../assets/icons/wordpress.svg";
import WIX from "../../assets/icons/wix.svg";
import Squarespace from "../../assets/icons/squarespace.svg";
import Pay_Pal from "../../assets/icons/pay-pal.svg";
import Stripe from "../../assets/icons/stripe.svg";
import Intercom from "../../assets/icons/intercom.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Marketo from "../../assets/icons/marketo.svg";
import Mailchimp from "../../assets/icons/mailchimp.svg";
import Gmail from "../../assets/icons/gmail.svg";
import Salesforce from "../../assets/icons/salesforce-ace.svg";
import Hubspot from "../../assets/icons/hubspot.svg";
import Microsoft_Dynamics from "../../assets/icons/microsoft_dynamics.svg";
import Gong from "../../assets/icons/gong.svg";
import Apple from "../../assets/icons/apple.svg";
import Chrome from "../../assets/icons/chrome.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import Edge from "../../assets/icons/microsoft-edge.svg";
import Firefox from "../../assets/icons/firefox.svg";
import MsOffice from "../../assets/icons/ms-office-360.svg";
import ICloud from "../../assets/icons/apple-icloud.svg";
import OutlookCalendar from "../../assets/icons/ms-outlook.svg";
import Webex from "../../assets/icons/webex.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import Asistent from "../../assets/icons/google-analytics.svg";
import ExchangeCalendar from "../../assets/icons/exchange-calendar.svg";
import ZoomIcon from "../../assets/icons/zoom.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoogleMeet from "../../assets/icons/google-meet.svg";
import Okta from "../../assets/icons/okta.svg";
import Microsoft_Azure from "../../assets/icons/microsoft-azure.svg";
import one_login from "../../assets/icons/one-login.svg";
import Ping_Identity from "../../assets/icons/ping-identity.svg";
import Duo from "../../assets/icons/duo.svg";
import Single_SignIn from "../../assets/icons/sso.svg";
import Zapier from "../../assets/icons/zapier.svg";
import Console from "../../assets/icons/console.svg";
import Microsoft_Power from "../../assets/icons/microsoft_power.svg";
import AdminLeftMenu from "../../templates/layouts/private/admin/left-menu";
import { Collapse } from "antd";
const { Panel } = Collapse; 

const Integrations = (props) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [page, setPage] = useState("all");
  const [sidebardropdownOpen, setSidebarDropdownOpen] = useState(false);
  const toggleSidebar = () => setSidebarDropdownOpen((prevState) => !prevState);
  const [sidebardropdownOpen1, setSidebarDropdownOpen1] = useState(false);
  const toggleSidebar1 = () => setSidebarDropdownOpen1((prevState) => !prevState);
  const [sidebardropdownOpen2, setSidebarDropdownOpen2] = useState(false);
  const toggleSidebar2 = () => setSidebarDropdownOpen2((prevState) => !prevState);
  const [sidebardropdownOpen3, setSidebarDropdownOpen3] = useState(false);
  const toggleSidebar3 = () => setSidebarDropdownOpen3((prevState) => !prevState);
  const [sidebardropdownOpen4, setSidebarDropdownOpen4] = useState(false);
  const toggleSidebar4 = () => setSidebarDropdownOpen4((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const sidebarCollapse = () => setSideBar((prevState) => !prevState);

  let header1 = (
    <h2>
      Event Type <Link to="/integrations"></Link>
    </h2>
  );
  let header2 = (
    <h2>
      Event Details <Link to="/integrations"></Link>
    </h2>
  );
  let header3 = (
    <h2>
      Availability <Link to="/integrations"></Link>
    </h2>
  );
  let header4 = (
    <h2>
      Advanced <Link to="/integrations"></Link>
    </h2>
  );
  let StaffHeader1 = (
    <p className="prof_img d-flex align-items-center">Staff Name</p>
  );
  const [advanceTab, setAdvanceTab] = useState(false);
  const [freeEvent, setFreeEvent] = useState(false);
  const [showAllAssignments, setShowAllAssignments] = useState(false);
  const showStaffAssignment = () => {
    setShowAllAssignments(true);
  };

  return (
    <>
      <Container fluid>
        <Row>
        <Col xl="2" lg="3">
            <div className={`integ-sidebar d-lg-block d-none ${sideBar ? "active" : ''}`}>
              <div className="head">
                <div className="head-logo">
                  <img src={sideBar ? LogoIcon : Logo} alt="Logo" />
                  <button
                    type="button"
                    className="side-colappse"
                    onClick={sidebarCollapse}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        d="M7.70726 4.70711C8.09778 4.31658 8.09778 3.68342 7.70726 3.29289C7.31673 2.90237 6.68357 2.90237 6.29304 3.29289L1.58594 8L6.29304 12.7071C6.68357 13.0976 7.31673 13.0976 7.70726 12.7071C8.09778 12.3166 8.09778 11.6834 7.70726 11.2929L4.41436 8L7.70726 4.70711Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M13.7073 4.70711C14.0978 4.31658 14.0978 3.68342 13.7073 3.29289C13.3167 2.90237 12.6836 2.90237 12.293 3.29289L7.58594 8L12.293 12.7071C12.6836 13.0976 13.3167 13.0976 13.7073 12.7071C14.0978 12.3166 14.0978 11.6834 13.7073 11.2929L10.4144 8L13.7073 4.70711Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="body scrole cust-main-sidebar">
                <AdminLeftMenu></AdminLeftMenu>
                <div className="bot">
                  <div className="upgrading">
                    <div className="icon">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            d="M12.5 14a1 1 0 1 1-2 0v-4h1.238c1.618 0 2.567-1.821 1.639-3.147L9.68 1.573A1 1 0 0 1 11.319.427l3.696 5.28c1.705 2.435.243 5.707-2.515 6.223V14Z"
                            fill="#fe9920"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.627 1.558a2 2 0 0 0-3.255 0L.468 7.023A1.25 1.25 0 0 0 1.486 9H3v4a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V9h1.514a1.25 1.25 0 0 0 1.017-1.977L7.627 1.558ZM5 7H2.943L6 2.72 9.057 7H7v6H5V7Z"
                            fill="#fe9920"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div className="text">
                      <h2>Upgrade your subscription</h2>
                      <p>Get even more of the Calendly features you need.</p>
                    </div>
                  </div>
                  <Link to="#">
                    <span className="me-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 10 10"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                      >
                        <path
                          d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z"
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M5 3.269V5l1.759 2.052"
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </span>
                    Availability
                  </Link>
                  <Link to="#">
                    <span className="me-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1 3C1 1.89543 1.89543 1 3 1H5C6.10457 1 7 1.89543 7 3V5C7 6.10457 6.10457 7 5 7H3C1.89543 7 1 6.10457 1 5V3ZM3 3H5V5H3L3 3Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 3C9 1.89543 9.89543 1 11 1H13C14.1046 1 15 1.89543 15 3V5C15 6.10457 14.1046 7 13 7H11C9.89543 7 9 6.10457 9 5V3ZM11 3H13V5H11V3Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3 9C1.89543 9 1 9.89543 1 11V13C1 14.1046 1.89543 15 3 15H5C6.10457 15 7 14.1046 7 13V11C7 9.89543 6.10457 9 5 9H3ZM5 11H3L3 13H5V11Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 11C9 9.89543 9.89543 9 11 9H13C14.1046 9 15 9.89543 15 11V13C15 14.1046 14.1046 15 13 15H11C9.89543 15 9 14.1046 9 13V11ZM11 11H13V13H11V11Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    Integrations & apps
                  </Link>
                  <Link to="#">
                    <span className="me-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M8.00018 8.00012C7.4479 8.00012 7.00018 8.44784 7.00018 9.00012V11.0001C7.00018 11.5524 7.4479 12.0001 8.00018 12.0001C8.55247 12.0001 9.00018 11.5524 9.00018 11.0001V9.00012C9.00018 8.44784 8.55247 8.00012 8.00018 8.00012Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.34185 1.44734C8.78906 0.34177 7.21135 0.341772 6.65857 1.44734L4.553 5.65849L2.26947 4.51672C1.20621 3.9851 -0.0234556 4.84253 0.10782 6.02401L0.907485 13.221C1.02003 14.2339 1.87616 15.0001 2.89525 15.0001H13.1052C14.1243 15.0001 14.9804 14.2339 15.0929 13.221L15.8926 6.02401C16.0239 4.84253 14.7942 3.9851 13.731 4.51672L11.4474 5.65849L9.34185 1.44734ZM5.44742 8.34177L8.00021 3.2362L10.553 8.34177L13.8033 6.7166L13.1052 13.0001L2.89525 13.0001L2.19708 6.7166L5.44742 8.34177Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    Admin center
                  </Link>
                  <Link to="#" className="side-help-option">
                    <span className="me-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M8.85002 9.25H7.09002C7.09002 8.56075 7.5948 8.09866 8.0637 7.66941C8.47035 7.29715 8.85002 6.94959 8.85002 6.5C8.85002 6.115 8.59702 5.862 8.03602 5.862C7.43102 5.862 7.06802 6.269 6.84802 6.786L5.33002 5.917C5.81402 4.784 6.88102 4.146 8.10202 4.146C9.35602 4.146 10.61 4.916 10.61 6.346C10.61 7.3213 9.98681 7.89541 9.48209 8.36037C9.13862 8.67678 8.85002 8.94265 8.85002 9.25Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M9.01502 11.109C9.01502 11.681 8.54202 12.154 7.97002 12.154C7.39802 12.154 6.92502 11.681 6.92502 11.109C6.92502 10.537 7.39802 10.064 7.97002 10.064C8.54202 10.064 9.01502 10.537 9.01502 11.109Z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    Help
                    <div className="integ-sidebar-dropdown">
                      <Dropdown
                        isOpen={sidebardropdownOpen}
                        toggle={toggleSidebar}
                      >
                        <DropdownToggle>
                          <span>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                            >
                              <path
                                d="M12.3535 8.06058L8.7071 11.707C8.31658 12.0976 7.68341 12.0976 7.29289 11.707L3.64644 8.06058C3.33146 7.7456 3.55454 7.20703 4 7.20703H12C12.4454 7.20703 12.6685 7.7456 12.3535 8.06058Z"
                                fill="#000"
                              ></path>
                            </svg>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <span className="menu-logo me-3">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <path
                                  d="M2 1C2.55228 1 3 1.44772 3 2V13H14C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15H3C1.89543 15 1 14.1046 1 13V2C1 1.44772 1.44772 1 2 1Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M14 10C13.4477 10 13 9.55229 13 9V4.41421L7.70711 9.70711C7.31658 10.0976 6.68342 10.0976 6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289L11.5858 3H7C6.44772 3 6 2.55228 6 2C6 1.44772 6.44772 1 7 1H15V9C15 9.55229 14.5523 10 14 10Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                            Help center
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="menu-logo me-3">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <path
                                  d="M2 1C2.55228 1 3 1.44772 3 2V13H14C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15H3C1.89543 15 1 14.1046 1 13V2C1 1.44772 1.44772 1 2 1Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M14 10C13.4477 10 13 9.55229 13 9V4.41421L7.70711 9.70711C7.31658 10.0976 6.68342 10.0976 6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289L11.5858 3H7C6.44772 3 6 2.55228 6 2C6 1.44772 6.44772 1 7 1H15V9C15 9.55229 14.5523 10 14 10Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                            Calendly Community
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="menu-logo me-3">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <g clip-path="url(#clip0_3064_75365)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V9C16 10.6569 14.6569 12 13 12H10.3546L4.90994 15.0864C4.08574 15.602 3 15.0118 3 14.0247V12C1.34315 12 0 10.6569 0 9V3ZM3 2C2.44772 2 2 2.44772 2 3V9C2 9.55229 2.44772 10 3 10H5V12.7364L9.82719 10H13C13.5523 10 14 9.55228 14 9V3C14 2.44772 13.5523 2 13 2H3Z"
                                    fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_3064_75365">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            Chat with us
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="menu-logo me-3">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <g clip-path="url(#clip0_3064_75365)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V9C16 10.6569 14.6569 12 13 12H10.3546L4.90994 15.0864C4.08574 15.602 3 15.0118 3 14.0247V12C1.34315 12 0 10.6569 0 9V3ZM3 2C2.44772 2 2 2.44772 2 3V9C2 9.55229 2.44772 10 3 10H5V12.7364L9.82719 10H13C13.5523 10 14 9.55228 14 9V3C14 2.44772 13.5523 2 13 2H3Z"
                                    fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_3064_75365">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            Give feedback
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="10" lg="9">
            <Row className="integ-header my-3">
              <Col className="col-md-6 col-3">
                <div className="integ-open-sidebar">
                  <button
                    type="button"
                    class="d-lg-none me-2 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#chatListSidebar"
                    aria-expanded="false"
                    aria-controls="chatListSidebar"
                  >
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M1 4C1 3.44772 1.44772 3 2 3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H2C1.44772 5 1 4.55228 1 4Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M1 8C1 7.44772 1.44772 7 2 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H2C1.44772 9 1 8.55228 1 8Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M1 12C1 11.4477 1.44772 11 2 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H2C1.44772 13 1 12.5523 1 12Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </Col>
              <Col className="col-md-6 col-9">
                <div className="invite-user">
                  <div class="buttonnotgcont">
                    <button class="invite-btn">
                      <a class="text-white align-item" href="#">
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 4C1.5 1.79086 3.29086 0 5.5 0C7.70914 0 9.5 1.79086 9.5 4C9.5 6.20914 7.70914 8 5.5 8C3.29086 8 1.5 6.20914 1.5 4ZM5.5 6C4.39543 6 3.5 5.10457 3.5 4C3.5 2.89543 4.39543 2 5.5 2C6.60457 2 7.5 2.89543 7.5 4C7.5 5.10457 6.60457 6 5.5 6Z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M12.5 6C11.9477 6 11.5 6.44772 11.5 7V9H9.5C8.94771 9 8.5 9.44772 8.5 10C8.5 10.5523 8.94771 11 9.5 11H11.5V13C11.5 13.5523 11.9477 14 12.5 14C13.0523 14 13.5 13.5523 13.5 13V11H15.5C16.0523 11 16.5 10.5523 16.5 10C16.5 9.44772 16.0523 9 15.5 9H13.5V7C13.5 6.44772 13.0523 6 12.5 6Z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M5.5 9C5.84247 9 6.17689 9.03443 6.5 9.10002V11.1707C6.18722 11.0602 5.85064 11 5.5 11C4.19378 11 3.08254 11.8348 2.67071 13H10.4C10.4656 13.3231 10.5 13.6575 10.5 14C10.5 14.5523 10.0523 15 9.5 15H1.5C0.947716 15 0.5 14.5523 0.5 14C0.5 11.2386 2.73858 9 5.5 9Z"
                            fill="#fff"
                          ></path>
                        </svg>
                        <span className="ms-3">Invite User</span>
                      </a>
                    </button>
                  </div>
                  <div className="custom-dropdown">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        <div className="imageaccountnav user-pic">
                          <img
                            src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-624-1700066969.jpg"
                            alt="user"
                          />
                        </div>
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              d="M12.3535 8.06058L8.7071 11.707C8.31658 12.0976 7.68341 12.0976 7.29289 11.707L3.64644 8.06058C3.33146 7.7456 3.55454 7.20703 4 7.20703H12C12.4454 7.20703 12.6685 7.7456 12.3535 8.06058Z"
                              fill="#000"
                            ></path>
                          </svg>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="scroller">
                          <DropdownItem>
                            <span className="menu-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#0C4767" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="mr-50"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            </span>
                            Profile
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#0C4767" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="mr-50"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                            </span>
                            Subscription
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#0C4767"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="mr-50"
                              >
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                              </svg>
                            </span>
                            Setting
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 21.8 21.8"
                                class="mr-50"
                              >
                                <g transform="translate(-220.1 -32.1)">
                                  <g transform="translate(218 31)">
                                    <rect
                                      width="20"
                                      height="18"
                                      rx="2"
                                      transform="translate(3 4)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></rect>
                                    <line
                                      y2="3"
                                      transform="translate(18 2)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                    <line
                                      y2="3"
                                      transform="translate(8 2)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                    <line
                                      x2="20"
                                      transform="translate(3 9)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                  </g>
                                  <line
                                    x2="5"
                                    transform="translate(224 43.5)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    x2="8.5"
                                    transform="translate(224 47.5)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                </g>
                              </svg>
                            </span>
                            Calendar Connections
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24.563 24.563"
                                class="mr-50"
                              >
                                <g
                                  id="Group_9368"
                                  data-name="Group 9368"
                                  transform="translate(-64 -85)"
                                >
                                  <g
                                    id="Rectangle_3114"
                                    data-name="Rectangle 3114"
                                    transform="translate(67.281 88.281)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      rx="3"
                                      stroke="none"
                                    ></rect>
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      rx="2"
                                      fill="none"
                                    ></rect>
                                  </g>
                                  <line
                                    id="Line_5"
                                    data-name="Line 5"
                                    y2="2.724"
                                    transform="translate(72.333 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_13"
                                    data-name="Line 13"
                                    y2="2.7"
                                    transform="translate(65 102.358) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_16"
                                    data-name="Line 16"
                                    y2="2.821"
                                    transform="translate(84.742 102.358) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_8"
                                    data-name="Line 8"
                                    y2="2.478"
                                    transform="translate(72.333 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_6"
                                    data-name="Line 6"
                                    y2="2.724"
                                    transform="translate(76.845 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_12"
                                    data-name="Line 12"
                                    y2="2.7"
                                    transform="translate(65 97.845) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_15"
                                    data-name="Line 15"
                                    y2="2.821"
                                    transform="translate(84.742 97.845) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_9"
                                    data-name="Line 9"
                                    y2="2.478"
                                    transform="translate(76.845 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_7"
                                    data-name="Line 7"
                                    y2="2.724"
                                    transform="translate(81.358 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_11"
                                    data-name="Line 11"
                                    y2="2.7"
                                    transform="translate(65 93.333) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_14"
                                    data-name="Line 14"
                                    y2="2.821"
                                    transform="translate(84.742 93.333) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_10"
                                    data-name="Line 10"
                                    y2="2.478"
                                    transform="translate(81.358 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <g
                                    id="Group_9367"
                                    data-name="Group 9367"
                                    transform="translate(70.711 95.025)"
                                  >
                                    <path
                                      id="Path_24108"
                                      data-name="Path 24108"
                                      d="M81.371,57.179l-3.243,1.21,3.243,1.235"
                                      transform="translate(-78.128 -56.05)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></path>
                                    <path
                                      id="Path_24110"
                                      data-name="Path 24110"
                                      d="M78.128,57.179l3.243,1.21-3.243,1.235"
                                      transform="translate(-70.231 -56.05)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></path>
                                    <path
                                      id="Path_24109"
                                      data-name="Path 24109"
                                      d="M84.248,56l-2.265,4.778"
                                      transform="translate(-77.471 -56)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-width="1.4"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            Embed Code
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="18"
                                viewBox="0 0 22.694 25.458"
                                class="mr-50"
                              >
                                <g
                                  id="Group_9380"
                                  data-name="Group 9380"
                                  transform="translate(-253.376 -196.913)"
                                >
                                  <path
                                    id="Path_24117"
                                    data-name="Path 24117"
                                    d="M263.054,220.788a3.294,3.294,0,0,1-3.194-3.073h.946a.331.331,0,0,0,.321-.34.359.359,0,0,0-.095-.243l-2.246-2.39a.307.307,0,0,0-.453,0h0l-2.246,2.39a.357.357,0,0,0,0,.483.31.31,0,0,0,.228.1h.976a3.956,3.956,0,0,0,3.836,3.756h1.925a.342.342,0,0,0,0-.683Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24118"
                                    data-name="Path 24118"
                                    d="M254.607,212.355a.332.332,0,0,1-.331-.331V210.7a4.974,4.974,0,0,1,9.947,0v1.327a.333.333,0,0,1-.331.331Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-miterlimit="10"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24119"
                                    data-name="Path 24119"
                                    d="M256.265,200.8a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,256.265,200.8Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-miterlimit="10"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24120"
                                    data-name="Path 24120"
                                    d="M265.554,221.471a.331.331,0,0,1-.331-.331v-1.327a4.974,4.974,0,0,1,9.947,0v1.327a.332.332,0,0,1-.331.331Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24121"
                                    data-name="Path 24121"
                                    d="M267.187,209.914a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,267.187,209.914Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                            Admin Referral
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#0C4767"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="mr-50"
                              >
                                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                <line x1="12" y1="2" x2="12" y2="12"></line>
                              </svg>
                            </span>
                            Logout
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="all-integ">
              <Loader isShowLoader={isPageLoading} />
              <Col sm="12">
                <div className="integ-banner">
                  <Row className="justify-content-between align-items-center">
                    <Col md="8" lg="7">
                      <h1>
                        Connect <span className="text-brand">MeetOcto</span>{" "}
                        <br className="d-none d-lg-block" /> to your favorite
                        application.
                      </h1>
                    </Col>
                    <Col md="4" lg="5">
                      <img src={IntegrationGoupImg} alt="All Integration" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <Row>
                  <Col md="4" xl="3">
                    <div className="int-sidebar">
                      <Link
                        to="#"
                        className={`${page === "all" ? "active" : ""}`}
                        onClick={() => setPage("all")}
                      >
                        All Integrations
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "calendars" ? "active" : ""}`}
                        onClick={() => setPage("calendars")}
                      >
                        Calendars
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "most-use" ? "active" : ""}`}
                        onClick={() => setPage("most-use")}
                      >
                        Most Use
                      </Link>
                      <Link
                        to="#"
                        className={`${
                          page === "video-conferencing" ? "active" : ""
                        }`}
                        onClick={() => setPage("video-conferencing")}
                      >
                        Video conferencing
                      </Link>
                      <Link
                        to="#"
                        className={`${
                          page === "extensions-app" ? "active" : ""
                        }`}
                        onClick={() => setPage("extensions-app")}
                      >
                        Extensions and apps
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "sales-crm" ? "active" : ""}`}
                        onClick={() => setPage("sales-crm")}
                      >
                        Sales and CRM
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "marketing" ? "active" : ""}`}
                        onClick={() => setPage("marketing")}
                      >
                        Marketing
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "email" ? "active" : ""}`}
                        onClick={() => setPage("email")}
                      >
                        Email messaging
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "analytic" ? "active" : ""}`}
                        onClick={() => setPage("analytic")}
                      >
                        Analytics
                      </Link>
                      <Link
                        to="#"
                        className={`${
                          page === "embed-calendly" ? "active" : ""
                        }`}
                        onClick={() => setPage("embed-calendly")}
                      >
                        Embed Calendly
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "payments" ? "active" : ""}`}
                        onClick={() => setPage("payments")}
                      >
                        Payments
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "security" ? "active" : ""}`}
                        onClick={() => setPage("security")}
                      >
                        Security and compliance
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "api" ? "active" : ""}`}
                        onClick={() => setPage("api")}
                      >
                        API and connectors
                      </Link>
                    </div>
                  </Col>
                  <Col md="8" xl="9">
                    <div className="int-content">
                      <h2>All Integrations</h2>
                      <Row>
                        {(page === "all" || page === "calendars") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="link">
                                  <span>
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M3.707 7.2929C4.09752 7.68343 4.09752 8.31659 3.707 8.70712L3.207 9.20712C2.21681 10.1973 2.21681 11.8027 3.207 12.7929C4.19718 13.7831 5.8026 13.7831 6.79278 12.7929L7.29278 12.2929C7.68331 11.9024 8.31647 11.9024 8.707 12.2929C9.09752 12.6834 9.09752 13.3166 8.707 13.7071L8.207 14.2071C6.43576 15.9784 3.56402 15.9784 1.79278 14.2071C0.0215446 12.4359 0.021548 9.56414 1.79278 7.7929L2.29278 7.2929C2.68331 6.90238 3.31647 6.90238 3.707 7.2929Z"
                                        fill="#fe9920"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.2928 8.70712C11.9023 8.31659 11.9023 7.68343 12.2928 7.2929L12.7928 6.79291C13.783 5.80272 13.783 4.19731 12.7928 3.20712C11.8026 2.21693 10.1972 2.21693 9.20699 3.20712L8.707 3.70712C8.31647 4.09764 7.68331 4.09764 7.29278 3.70712C6.90226 3.31659 6.90226 2.68343 7.29278 2.2929L7.79278 1.79291C9.56402 0.0216699 12.4358 0.021667 14.207 1.7929C15.9782 3.56414 15.9782 6.43588 14.207 8.20712L13.707 8.70712C13.3165 9.09764 12.6833 9.09764 12.2928 8.70712Z"
                                        fill="#fe9920"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.707 5.2929C11.0975 5.68343 11.0975 6.31659 10.707 6.70712L6.707 10.7071C6.31647 11.0976 5.68331 11.0976 5.29278 10.7071C4.90226 10.3166 4.90226 9.68343 5.29278 9.2929L9.29278 5.2929C9.68331 4.90238 10.3165 4.90238 10.707 5.2929Z"
                                        fill="#fe9920"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                                <div className="img">
                                  <img
                                    src={GoogleCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Google Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={MsOffice} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Office 365 Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={ICloud} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Icloud Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={OutlookCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Outlook Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={ExchangeCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Exchange Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={ExchangeCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Exchange Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "most-use") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={GoogleCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Google Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={GoToIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Goto Meeting</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={TeamsIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Microsoft Teams</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="/integrations/zoom" className="int-col">
                                <div className="img">
                                  <img src={ZoomIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Zoom</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={GoogleMeet} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Google Meet</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Android} alt="Android" />
                                </div>
                                <div className="text">
                                  <h3>Calendly Android app</h3>
                                  <p>
                                    Access meetings and availability on the go.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={Apple} alt="Apple" />
                                </div>
                                <div className="text">
                                  <h3>Calendly iPhone app</h3>
                                  <p>
                                    Access meetings and share availability on
                                    the go.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Asistent} alt="Google Analytics" />
                                </div>
                                <div className="text">
                                  <h3>Google Analytics</h3>
                                  <p>
                                    Track engagement with your booking pages.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "video-conferencing") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={GoToIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Goto Meeting</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={TeamsIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Microsoft Teams</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="/integrations/zoom" className="int-col">
                                <div className="img">
                                  <img src={ZoomIcon} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Zoom</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={GoogleMeet} alt="Google Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Google Meet</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Webex} alt="Webex" />
                                </div>
                                <div className="text">
                                  <h3>Webex</h3>
                                  <p>
                                    Include Webex details in your Calendly
                                    events.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}

                        {(page === "all" || page === "extensions-app") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Android} alt="Android" />
                                </div>
                                <div className="text">
                                  <h3>Calendly Android app</h3>
                                  <p>
                                    Access meetings and availability on the go.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={OutlookCalendar} alt="Android" />
                                </div>
                                <div className="text">
                                  <h3>Outlook Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={Apple} alt="Apple" />
                                </div>
                                <div className="text">
                                  <h3>Calendly iPhone app</h3>
                                  <p>
                                    Access meetings and share availability on
                                    the go.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Chrome} alt="Chrome" />
                                </div>
                                <div className="text">
                                  <h3>Chrome</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={Slack} alt="Slack Icon" />
                                </div>
                                <div className="text">
                                  <h3>Slack</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Linkedin} alt="linkedin" />
                                </div>
                                <div className="text">
                                  <h3>Linkedin</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Firefox} alt="Firefox Icon" />
                                </div>
                                <div className="text">
                                  <h3>Firefox</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Edge} alt="Edge" />
                                </div>
                                <div className="text">
                                  <h3>Edge</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Intercom} alt="Intercom" />
                                </div>
                                <div className="text">
                                  <h3>Intercom</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "sales-crm") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Linkedin} alt="linkedin" />
                                </div>
                                <div className="text">
                                  <h3>Linkedin</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Salesforce} alt="Salesforce Icon" />
                                </div>
                                <div className="text">
                                  <h3>Salesforce</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Hubspot} alt="Hubspot" />
                                </div>
                                <div className="text">
                                  <h3>Hubspot</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img
                                    src={Microsoft_Dynamics}
                                    alt="Microsoft_Dynamics Icon"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Dynamics</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Gong} alt="Gong Icon" />
                                </div>
                                <div className="text">
                                  <h3>Gong</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}

                        {(page === "all" || page === "marketing") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Asistent} alt="Google Analytics" />
                                </div>
                                <div className="text">
                                  <h3>Google Analytics</h3>
                                  <p>
                                    Track engagement with your booking pages.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Salesforce} alt="Salesforce Icon" />
                                </div>
                                <div className="text">
                                  <h3>Salesforce</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Hubspot} alt="Hubspot" />
                                </div>
                                <div className="text">
                                  <h3>Hubspot</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Intercom} alt="Intercom" />
                                </div>
                                <div className="text">
                                  <h3>Intercom</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Facebook} alt="Facebook Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Facebook Pixel</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Marketo} alt="Marketo Icon" />
                                </div>
                                <div className="text">
                                  <h3>Marketo</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Mailchimp} alt="Mailchimp Icon" />
                                </div>
                                <div className="text">
                                  <h3>Mailchimp</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "email") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Gmail} alt="Gmail Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Gmail for Workflows</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Mailchimp} alt="Mailchimp Icon" />
                                </div>
                                <div className="text">
                                  <h3>Mailchimp</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={OutlookCalendar}
                                    alt="Google Calendar"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Outlook Calendar</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "analytic") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Asistent} alt="Google Analytics" />
                                </div>
                                <div className="text">
                                  <h3>Google Analytics</h3>
                                  <p>
                                    Track engagement with your booking pages.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Facebook} alt="Facebook Calendar" />
                                </div>
                                <div className="text">
                                  <h3>Facebook Pixel</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "embed-calendly") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Intercom} alt="Intercom" />
                                </div>
                                <div className="text">
                                  <h3>Intercom</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={WordPress} alt="Wordpress" />
                                </div>
                                <div className="text">
                                  <h3>WordPress</h3>
                                  <p>
                                    Track engagement with your booking pages.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={WIX} alt="WIX" />
                                </div>
                                <div className="text">
                                  <h3>WIX</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img
                                    src={Squarespace}
                                    alt="Squarespace  Icon"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Squarespace</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}

                        {(page === "all" || page === "payments") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Pay_Pal} alt="Pay Pal Analytics" />
                                </div>
                                <div className="text">
                                  <h3>Pay Pal</h3>
                                  <p>
                                    Track engagement with your booking pages.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Stripe} alt="Stripe Payment " />
                                </div>
                                <div className="text">
                                  <h3>Stripe</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}

                        {(page === "all" || page === "security") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={Okta} alt="Okta logo" />
                                </div>
                                <div className="text">
                                  <h3>Okta</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>

                            <Col md="6" xxl="3" xl="4">
                              <Link to="/integrations/zoom" className="int-col">
                                <div className="img">
                                  <img
                                    src={Microsoft_Azure}
                                    alt="Microsoft Azure"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Microsoft Azure</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={one_login} alt="One Login Logo" />
                                </div>
                                <div className="text">
                                  <h3>One Login</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img
                                    src={Ping_Identity}
                                    alt="Ping_Identity Logo"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Ping Identity</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img src={Duo} alt="Duo Logo" />
                                </div>
                                <div className="text">
                                  <h3>Duo</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link to="all-integrations" className="int-col">
                                <div className="img">
                                  <img
                                    src={Single_SignIn}
                                    alt="Single_SignIn Logo"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Single sign-on</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                        {(page === "all" || page === "api") && (
                          <>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Zapier} alt="Zapier" />
                                </div>
                                <div className="text">
                                  <h3>Zapier</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img
                                    src={Microsoft_Power}
                                    alt="Microsoft Power Logo"
                                  />
                                </div>
                                <div className="text">
                                  <h3>Microsoft Power</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                            <Col md="6" xxl="3" xl="4">
                              <Link
                                to="/integrations/google-meet"
                                className="int-col"
                              >
                                <div className="img">
                                  <img src={Console} alt="Console Logo" />
                                </div>
                                <div className="text">
                                  <h3>API and webhooks</h3>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing an typesetting industry.
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Integrations;
